.listBody {
  margin-top: 30px;
}

.paginatorBody {
  margin-top: 20px;
}

.numbering {
  float: left;
  margin-right: 10px;
}
